import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import { Link, useNavigate} from "react-router-dom";
import { format } from 'date-fns';
import ScrollToTop from "../../units/ScrollToTop";
import { useEffect, useState } from "react";
export default function BookingRoom ({startDate, endDate, selectedCity, selectedBranch, setStartDate, setEndDate, setSelectedCity, setSelectedBranch, tabSelect}){

    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const city = t('booking.city', {returnObjects:true})
    const branch = t('booking.branch', {returnObjects:true})
    const [currentBranch, setCurrentBranch] = useState(selectedBranch)
    const language = i18n.language || 'en'
    const handleSendData = () => {
        const data = {
            startDate: startDate ,
            // ? format(startDate, 'yyyy-MM-dd'): '',
            endDate: endDate ,
            // ? format(endDate,'yyyy-MM-dd'): '',
            selectedCity,
            selectedBranch
        }
        if(tabSelect > 0) {
          navigate(`/${language}/${selectedBranch}/room/`,{state: data})
          window.location.reload()
        }
        else {
          navigate(`/${language}/reservation/`,{state: data})
        }
      }
    
    const flatBranches = [].concat(...branch)
    const filteredBranches = flatBranches.filter(b => b.city_id == selectedCity)

    const handleBranchValue = (cityId) => {
        switch(cityId) {
          case 'hotel-hn':
            setSelectedBranch('hai-ba-trung-detail')
            break;
          case 'hotel-dn': 
            setSelectedBranch('da-nang');
            break;
          case 'hotel-hp':
            setSelectedBranch('hai-phong');
            break;
          default:
            setSelectedBranch('le-thanh-ton-detail');
        }
      };

      const handleCityChange = (e) => {
        const cityId = e.target.value;
        setSelectedCity(cityId);
        handleBranchValue(cityId);
      };
      useEffect(() => {
        if (currentBranch !== selectedBranch) {
          // const userConfirmed = window.confirm(t('booking.note'));
          // if (userConfirmed) {
            const data = {
              startDate,
              endDate,
              selectedCity,
              selectedBranch,
            };
            navigate(`/${language}/${selectedBranch}/room/`, { state: data });
          }
          else{
            setSelectedBranch(currentBranch)
          }
        // }
      }, [selectedBranch, currentBranch, startDate, endDate, selectedCity, language, navigate]);
    return(
        <div>              
        <div className="content__booking">
        <div className="container">
            <div className="row gx-3 p-0 justify-content-center">
                <div className="col-md-12 ">
                <div className="content__booking-title">{t('booking.title')}</div>
                </div>
                <div className="col-md-2">
                  <Flatpickr 
                  value={startDate}
                  options={{
                    minDate:'today',
                    dateFormat:'Y-m-d'}} 
                  className="col-md-12 content__booking-date webkit-appearance" 
                  placeholder={t('booking.date_in')} 
                  onChange={(dates)=>{
                    setStartDate(dates[0]);
                    }} />
                    </div>
                <div className="col-md-2">
                  <Flatpickr 
                  value={endDate}
                  options={{
                    minDate:new Date(startDate),
                    dateFormat:'Y-m-d'}} 
                  className="col-md-12 content__booking-date webkit-appearance" 
                  placeholder={t('booking.date_out')} 
                  onChange={(dates) =>{
                    setEndDate(dates[0])
                  }} />
                  </div>
                <div className="col-md-2">
                <div className="content__booking-branch">
                    <select className="content__booking-branch-select"
                    id={selectedCity}
                    value={selectedCity}
                    onChange={handleCityChange}>
                        {city.map(item => (
                            <option key={item.id} value={item.id}>{item.city_name}</option>
                        ))}
                    </select>
                </div>
                </div>
                <div className="col-md-2">
                <div className="content__booking-hotel-select">
                    <select className="content__booking-hotel-name-select"
                    // disabled={!selectedCity}
                    value={selectedBranch}
                    onChange={(e)=> {
                      setSelectedBranch(e.target.value)
                      }}>
                        {filteredBranches.map((item, index) => (
                            <option key = {item.branch_id} value={item.branch_id}>{item.branch_name}</option>
                        ))}
                    </select>
                </div>
                </div>
                <div className="col-md-2">
                <button
                onClick={handleSendData}
                className="base__btn btn--mobile" style={{marginTop:10}}>{t('booking.reserve')}
                {/* {startDate == undefined && endDate == undefined && selectedBranch == undefined ? <Link to = {`/Reservation`} ></Link> :  <Link to = {`/${selectedBranch}/room`} ></Link> } */}
                </button> 
                </div>  
                </div>
            </div>
         </div>
        </div>
    )
    
}